<template>
  <div class="about">
  <div class="about2">
    <p class="about_p">ABOUT</p>
    <h1 class="about_h1">勝間田タイヤについて</h1>

    <div class="about_greet">
      <p>

      株式会社 勝間田タイヤは1974年創業後、地域のみなさまに支えられて創業{{founded}}年目を迎えました。<br>
      お店はブリヂストンの専門店「ミスタータイヤマン富士山御殿場店」として2016年リニューアルオープンしました。
      今後も皆様のご期待にお応えできるよう益々精進してまいります。

      </p>
    </div>

    <div class="img_company1"><img src="../assets/front_tireman.webp" alt="会社"></div>

    <div class="about_summary">
      <h1>会社概要</h1>
      <dl class="about_address">
        <dt>商号</dt><dd>株式会社勝間田タイヤ</dd>
      </dl>
      <dl class="about_address">
        <dt>所在地</dt><dd>〒412-0041<br>静岡県御殿場市茱萸沢241-1</dd>
      </dl>
      <dl class="about_history">
        <dt>創業</dt><dd>1974年</dd>
      </dl>
      <dl class="about_contents">
        <dt>事業内容</dt><dd>タイヤ販売及び車関連商品の販売</dd>
      </dl>
      <dl class="about_contents">
        <dt>登録番号</dt><dd>T5080101004012</dd>
      </dl>
      <dl class="about_contents">
        <dt>代表取締役社長</dt><dd>勝間田 和優</dd>
      </dl>
      <dl class="about_time">
        <dt>営業時間</dt><dd>8:00-18:00</dd>
      </dl>
      <dl class="about_tel">
        <dt>定休日</dt><dd>日曜・祝日<br>
        年末年始<br>
        (12月は日曜祝日も営業)</dd>
        <dt>電話番号</dt><dd>0550-89-1219</dd>
      </dl>
    </div>
    </div>

    <div>
    <Footer/>
    </div>

  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'AboutView',
  data(){
    return{
      founded: ''
      }
    },
  components: {
    Footer
  },
  mounted: function() {
    this.updateTime();
    },
  methods:{
    updateTime: function() {
    let currentdate = new Date()
    if (parseInt(currentdate.getMonth()) >= 9){
      this.founded = currentdate.getFullYear()-1973
    }else{
      this.founded = currentdate.getFullYear()-1974
    }
    }
  }
}
</script>

<style scoped>
.about2{
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
}
.about{
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.about_h1{
  margin-top: -0.5rem;
  padding-bottom: 2rem;
  text-align:left;
  font-size: 1.4rem;
}
.about_p{
  padding-top: 6rem;
  text-align:left;
  font-size: 0.9rem;
}
.img_gentleman img{
  width: 30%;
}
.img_company1 img{
  width: 100%;
  height: 100%;
}
.img_company1{
  padding-top: 3rem;
}

.about_summary h1{
  font-size: 1.4rem;
  text-align:left;
  padding-top: 2rem;
}


.about_address{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_history{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_contents{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_time{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 1.5em;
}
.about_tel{
    font-size: 0.9em;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: 540;
    line-height: 2.5em;
    padding-bottom: 50px;
}

.about dt{
  float: left;
  font-size: 0.84rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  line-height: 2rem;
}

.about dt::after{
  content: ":"
}
.about dd{
  text-align:left;
  padding-left: 5.2rem;
  font-size: 0.84rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  padding-left: 8rem;
  line-height: 2rem;
}

.about_greet p{
  font-size: 0.9rem;
  text-align:left;
  line-height: 1.8;
  letter-spacing: 0.03rem;
  font-weight: 500;
}

</style>
